$(document).ready(function(){
    let heightScreen = $( window ).height();
    let widthScreen = $( window ).width();
    let menu = $('.header-menu');
    let page = window.location.pathname;
    let menuMobBtn = $('.header-menu__mob-btn');
    let menuMob = $('.header-menu__mob-content');
    let menuExit = $('.header-menu__mob-exit-btn');
    if( page === "/" ){
        window.onscroll = function() {
            // Enable pc-menu after first section
            let scrolled = window.pageYOffset || document.documentElement.scrollTop;
            if( scrolled > heightScreen && widthScreen > 900 ){
                menu.css('display','block');
            }else
                menu.css('display' , 'none');

            // Find last position of socials block
            let socialLastPosition = 0;
            $('.section').each(function(){
                socialLastPosition += $(this).height() ;
            });
            socialLastPosition -= $('.footer').height();
            if(widthScreen > 768) {
                if(scrolled > socialLastPosition)
                    $('.header-social__content').css('display','none');
                else
                    $('.header-social__content').css('display','flex');
            }
        };
        // Event carousel slider
        let slideWidth = $( window ).width();
        if(slideWidth > 500){
            let slideCount = $('.section-events__slide').length;
            let left = 0;
            slideWidth -= (parseInt($('.main').css('margin-left')) + parseInt($('.section').css('padding-right')));
            $('.section-events__slides').css('width', slideWidth * (slideCount));
            $('.section-events__slide').css('width' , slideWidth);
            $('.section-events__pagination a').click(function(){
                let action = $(this).get(0).getAttribute('data-type');
                let slideWidth = $('.section-events__slide').width();
                let slideCount = $('.section-events__slide').length;
                let currentSlide = $('.section-events__pagination').find('.pagination__page--active').get(0).getAttribute('data-type');
                let paginationActive = $('.pagination__page--active');
                let slidesEnd = slideWidth * (slideCount-1);

                if(action === "prev"){
                    left += slideWidth;
                    if(left > 0 ){
                        left = -slidesEnd;
                        $('.section-events__slides').css('left' , left);
                        paginationActive.removeClass('pagination__page--active');
                        $('.pagination__page:eq('+(slideCount-1)+')').addClass('pagination__page--active');
                    }else{
                        $('.section-events__slides').css('left' , left);
                        paginationActive.removeClass('pagination__page--active');
                        $('.pagination__page:eq('+(currentSlide-1)+')').prev().addClass('pagination__page--active');
                    }
                }
                else if(action === "next"){
                    left -= slideWidth;
                    if(left < -slidesEnd){
                        left = 0;
                        $('.section-events__slides').css('left' , left);
                        paginationActive.removeClass('pagination__page--active');
                        $('.pagination__page:eq(0)').addClass('pagination__page--active');
                    }else{
                        $('.section-events__slides').css('left' , left);
                        paginationActive.removeClass('pagination__page--active');
                        $('.pagination__page:eq('+(currentSlide-1)+')').next().addClass('pagination__page--active');
                    }
                }
                else {
                    left -= (action - currentSlide) * slideWidth;
                    $('.section-events__slides').css('left', left);
                    paginationActive.removeClass('pagination__page--active');
                    $(this).addClass('pagination__page--active');
                }
            });
        }
    }else{
        let menuLogo = $('.header-menu__logo');
        $('.header-social__content').css('display' , 'none');
        if(widthScreen > 900){
            menu.css('display' , 'block');
            menuLogo.attr('src' , '../img/logo-white.png');
        }
        window.onscroll = function() {
            let scrolled = window.pageYOffset || document.documentElement.scrollTop;
            if( scrolled > heightScreen && widthScreen > 900 ){
                menuLogo.attr('src' , '../img/logo.png');
            }else
                menuLogo.attr('src' , '../img/logo-white.png');
        };
    }
    // menu active element
    $('.header-menu__nav a').each(function(){
       let link = '/' + $(this).attr('href');
       if(page === link)
           $(this).addClass('header-menu__item--active');
    });
    // open mobile menu
    menuMobBtn.click(function(){
        menuMob.css('display' , 'block');
        menuMob.addClass('mob-menu-active');
        $('body').css('overflow' , 'hidden');
    });
    // exit from mobile menu on click button
    menuExit.click(function(){
        menuMob.css('display' , 'none');
        $('body').css('overflow' , 'unset');
    });
    // exit from mobile menu on click overlay
    $('.header-menu__mob-content').click(function(){
        menuMob.css('display' , 'none');
        $('body').css('overflow' , 'unset');
    });
});
